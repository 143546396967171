<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <validation-observer ref="cardRules">
      <b-form>
        <b-card>
          <b-row>
            <b-col class="col-12">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="50"
                    :src="avatarUrl"
                    :text="avatarText(memberName)"
                    variant="primary"
                    class="d-none d-lg-block"
                  />
                </template>
                <h4 class="m-0">
                  {{ memberName }}
                </h4>
                <h5 class="m-0">
                  {{ formatDateBR(memberBirthdate) }} - {{ memberAge }} anos
                </h5>
                <h5>Data da solicitação: {{ formatDateBR(requestDate) }}</h5>
              </b-media>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col class="col-12">
              <h3>Dados da solicitação</h3>
            </b-col>
          </b-row>
          <div v-if="bariatricCardInfo">
            <b-row class="mt-2 ml-0">
              <b-col
                cols="8"
                lg="3"
              >
                <div
                  class="form-group row"
                >
                  <label
                    class="mr-1"
                    style="margin-top: 10px"
                    for="specialist-status"
                  >Status:</label>
                  <div>
                    <validation-provider
                      #default="{ errors }"
                      name="status"
                      rules="required"
                    >
                      <v-select
                        v-model="status"
                        :options="statusOption"
                        :reduce="option => option.id"
                        :clearable="false"
                        @option:selected="ableButtonSave"
                      >
                        <template v-slot:option="option">
                          <div
                            style="padding: 2px 0;"
                            v-html="option.label"
                          />
                        </template>
                        <template v-slot:selected-option="option">
                          <div
                            v-html="option.label"
                          />
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="8"
                lg="3"
              >
                <div class="mt-1">
                  <validation-provider
                    #default="{ errors }"
                    name="data da cirurgia"
                    rules="required"
                  >
                    <div class="p-0">
                      <b-form-group
                        label="Data da cirurgia:"
                        label-for="surgeryrDate"
                        class="mb-0"
                      >
                        <b-input-group class="input-group-merge">
                          <flat-pickr
                            id="surgeryDate"
                            v-model="surgeryDate"
                            class="form-control"
                            :config="configDate"
                            placeholder="Selecione"
                            :events="eventsToBeEmitted"
                            @on-open="ableButtonSave"
                          />
                          <b-input-group-append>
                            <b-input-group-text>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-calendar4"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z" />
                              </svg>
                            </b-input-group-text>
                          </b-input-group-append>
                        </b-input-group>

                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </div>
                  </validation-provider>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col
                cols="8"
                lg="3"
              >
                <div
                  class="form-group"
                >
                  <label
                    style="margin-top: 10px"
                    for="surgeryType"
                  >
                    Tipo de cirirgia:
                  </label>
                  <div>
                    <validation-provider
                      #default="{ errors }"
                      name="surgeryType"
                      rules="required"
                    >
                      <v-select
                        id="surgeryType"
                        v-model="surgeryTypeId"
                        :options="surgeryTypeOption"
                        label="name"
                        :reduce="surgeryTypeOption => surgeryTypeOption.id"
                        :clearable="false"
                        @option:selected="ableButtonSave"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                class="mt-3"
              >
                <b-button
                  type="button"
                  variant="outline"
                  class="mr-1 text-primary"
                  title="Cancelar"
                  @click="cancel"
                >
                  Cancelar
                </b-button>
                <b-button
                  type="button"
                  variant="primary"
                  class="ml-2"
                  :disabled="buttonDisable"
                  title="Salvar"
                  @click="save"
                >
                  Salvar
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BMedia, BAvatar, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BInputGroupText, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment'
import avatarText from '@/utils/filter'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    BButton,
    vSelect,
    flatPickr,

    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const status = ref('WAITING')
    const surgeryTypeId = ref(1)
    const statusOption = [
      {
        id: 'WAITING',
        label: '<span class="badge badge-bariatric badge-warning">Pendente</span>',
      },
      {
        id: 'APPROVED',
        label: '<span class="badge badge-bariatric badge-success">Aprovada</span>',
      },
      {
        id: 'REFUSED',
        label: '<span class="badge badge-bariatric badge-dark">Recusada</span>',
      },
    ]

    const surgeryTypeOption = ref([])
    const bariatricCardInfo = ref()
    const surgeryDate = ref('')
    const buttonDisable = ref(true)
    const eventsToBeEmitted = [
      'onOpen',
    ]
    const configDate = {
      mode: 'single',
      dateFormat: 'd/m/Y',
      locale: Portuguese,
    }
    const memberName = ref('')
    const memberBirthdate = ref('')
    const memberAge = ref('')
    const requestDate = ref('')
    const avatarUrl = ref('')

    function loadData() {
      this.$store.dispatch('bariatric/showBariatricCard', { bariatric_card_id: this.$route.params.id })
        .then(response => {
          if (response.data) {
            bariatricCardInfo.value = response.data.data.bariatric_card_info
            if (bariatricCardInfo.value.status === 'APPROVED') {
              this.$router.push({ path: '/bariatric', query: { tab: 'card' } })
            }
            status.value = bariatricCardInfo.value.status
            surgeryTypeId.value = bariatricCardInfo.value.surgery_type_id
            surgeryDate.value = moment(bariatricCardInfo.value.surgery_date).format('DD/MM/YYYY')
            memberName.value = bariatricCardInfo.value.member_name
            memberBirthdate.value = bariatricCardInfo.value.member_birthdate
            memberAge.value = bariatricCardInfo.value.member_age
            requestDate.value = bariatricCardInfo.value.created_at
            avatarUrl.value = bariatricCardInfo.value.avatar_url
          }
        })
        .catch(() => {
          this.$router.push('/bariatric', () => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ops... Algo deu errado, tente novamente',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
        })
    }
    function cancel() {
      this.$router.push('/bariatric')
    }

    function formatDateBR(value) {
      return moment(value).format('DD/MM/YYYY')
    }

    function ableButtonSave() {
      buttonDisable.value = false
    }

    function loadTypeOption() {
      this.$store.dispatch('bariatric/surgeryTypeList')
        .then(response => {
          surgeryTypeOption.value = response.data.data.surgery_types_info
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      status,
      surgeryTypeId,
      statusOption,
      surgeryTypeOption,
      bariatricCardInfo,
      surgeryDate,
      buttonDisable,
      configDate,
      memberName,
      memberBirthdate,
      memberAge,
      requestDate,
      avatarUrl,
      eventsToBeEmitted,
      avatarText,
      formatDateBR,
      loadData,
      cancel,
      ableButtonSave,
      loadTypeOption,
    }
  },

  created() {
    this.loadData()
    this.loadTypeOption()
  },

  methods: {
    save() {
      let titleToast = ''

      if (this.status === 'APPROVED') {
        titleToast = 'Solicitação de carteirinha aprovada com sucesso!'
      }

      if (this.status === 'REFUSED') {
        titleToast = 'Solicitação de carteirinha rejeitada com sucesso!'
      }

      if (this.status === 'WAITING') {
        titleToast = 'Dados da solicitação da carteirinha salvos com sucesso!'
      }

      this.$refs.cardRules.validate().then(success => {
        if (success) {
          this.buttonDisable = true
          const payload = {
            uuid: this.bariatricCardInfo.uuid,
            status: this.status,
            surgery_date: moment(this.surgeryDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            surgery_type_id: this.surgeryTypeId,
          }

          this.$store.dispatch('bariatric/updateBariatricCard', payload)
            .then(() => {
              this.$store.commit('bariatric/UPDATE_ACTION_TAB', 'card')
              this.$router.push({ path: '/bariatric', query: { tab: 'card' } }, () => {
                let buttonOnToast = false

                if (this.status === 'APPROVED' || this.status === 'REFUSED') {
                  buttonOnToast = true
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: titleToast,
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    button: buttonOnToast,
                    buttonColor: 'primary',
                    textButton: 'Desfazer',
                  },
                  listeners: {
                    buttonClicked: () => {
                      const payloadRollback = {
                        bariatric_card_ids: [this.bariatricCardInfo.id],
                        status: 'APPROVED',
                        is_rollback: true,
                      }
                      this.$store.dispatch('bariatric/updateBariatricCardsStatus', payloadRollback)
                        .then(() => {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Ação desfeita com sucesso',
                              icon: 'CheckCircleIcon',
                              variant: 'success',
                            },
                          })
                          this.$store.commit('bariatric/UPDATE_BARIATRIC_CARD_LIST_CHANGE', Date.now())
                        })
                    },
                  },
                })
              })
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ops... Algo deu errado, tente novamente',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
